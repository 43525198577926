'use strict';

var FormValidator = function (model_name, form_id, submit_btn_id, elem_property_map) {
    this.model_name = model_name;
    this.form = document.getElementById(form_id);
    this.submit_button = document.getElementById(submit_btn_id);

    this.elem_property_map = elem_property_map;

    this.init();
};

FormValidator.prototype = {
    has_error: false,
    submitted: false,
    submit_clicked: false,
    submit_button_old_inner: null,

    focused: false,

    end_validation_cbs: [],

    extra_validations: [],
    json_filters: [],
    json_response_filters: [],
    multi_elems: [],

    init: function() {
        if (this.form) {
            this.form.noValidate = true;
        }

        this.model_json = {};

        this.extra_validations = [];
        this.multi_elems = [];

        this.json_filters = [];
        this.json_response_filters = [];
    },

    setModelName: function(model_name) {
        this.model_name = model_name;
    },

    setElementPropertyMap: function (elem_prop_map) {
        this.elem_property_map = elem_prop_map;
    },

    getElementPropertyMap: function () {
        return this.elem_property_map;
    },

    enable: function() {
        var self = this;
        this.form.onsubmit = function () {
            if (self.submit_clicked === true) {
                if (!self.submitted) {
                    self.submit_button_old_inner = self.submit_button.innerHTML;
                    self.submit_button.innerHTML = "<i class=\"fa fa-spinner fa-spin\" aria-hidden=\"true\"></i>";
                    self.submit_button.disabled = true;
                }
                return self.validate();
            } else {
                return true;
            }
        };
        this.submit_button.addEventListener("click", function () {
            self.submit_clicked = true;
        }, true);
    },

    disable: function() {
        this.form.onsubmit = null;
    },

    findFormGroupForElement: function (form_elem) {
        var parent_elem = form_elem;
        do {
            if (parent_elem.classList.contains("form-group")) {
                break;
            }
        } while (parent_elem && (parent_elem = parent_elem.parentElement));

        return parent_elem;
    },

    setGroupClassOnElement: function (form_elem, class_name) {
        var parent_elem = this.findFormGroupForElement(form_elem);

        if (parent_elem) {
            parent_elem.classList.add(class_name);
        }
    },

    removeGroupClassOnElement: function (form_elem, class_name) {
        var parent_elem = this.findFormGroupForElement(form_elem);

        if (parent_elem) {
            parent_elem.classList.remove(class_name);
        }
    },

    setHelpMessageOnElement: function (form_elem, help_msg) {
        var next_elem = form_elem;

        while(next_elem && (next_elem = next_elem.nextElementSibling)) {
            if (next_elem.classList.contains("help-block")){
                break;
            }
        }

        if (next_elem) {
            next_elem.innerHTML = help_msg;
        } else {
            var form_group_elem = this.findFormGroupForElement(form_elem);
            if (form_group_elem) {
                var help_block = form_group_elem.querySelector(".help-block");
                if (help_block) {
                    help_block.innerHTML = help_msg;
                } 
            }
        }

    },

    clearErrorOnFormElem: function (elem) {
            this.removeGroupClassOnElement(elem, "has-error");
            this.setHelpMessageOnElement(elem, "");
    },

    clearErrors: function () {
        var num_elems = this.form.elements.length;
        this.has_error = false;

        for (var x = 0; x < num_elems; x++) {
            var form_elem = this.form.elements[x];
            this.clearErrorOnFormElem(form_elem);
        }
    },

    checkElementValidity: function (self) {
        var handler = function () {
            if (this.nodeName !== "INPUT" || this.checkValidity() === true) {
                self.removeGroupClassOnElement(this, "has-error");
                self.setHelpMessageOnElement(this, "");
                this.removeEventListener("keyup", handler);
            }
        };

        return handler;
    },

    setErrorOnFormElem: function (form_elem, property_error_msg, focus_elem) {
        if (typeof(focus_elem) !== "boolean") {
            focus_elem = true;
        }
        this.has_error = true;
        this.setGroupClassOnElement(form_elem, "has-error");
        this.setHelpMessageOnElement(form_elem, property_error_msg);
        form_elem.addEventListener("keyup", this.checkElementValidity(this));
        form_elem.addEventListener("change", this.checkElementValidity(this));
        if (focus_elem && !this.focused) {
            form_elem.focus();
            this.focused = true;
        }
    },

    endValidation: function () {
        if (!this.submitted) {
            return; 
        }

        if (this.has_error === false) {
            this.submit_button.disabled = false;
            this.submit_button.click();
            this.submit_button.disabled = true;
        } else {
            this.submit_button.innerHTML = this.submit_button_old_inner;
            this.submit_button.disabled = false;

            this.submitted = false;
        }

        this.runEndValidationCBs();
    },

    addEndValidationCB: function (cb) {
        this.end_validation_cbs.push(cb);
    },

    runEndValidationCBs: function () {
        for (var x = 0; x < this.end_validation_cbs.length; x++) {
            var cb = this.end_validation_cbs[x];

            cb.call();
        }
    },

    handleValidationResults: function (json_req) {
        var self = this;
        return function () {
            var response = null;
            if (json_req.readyState !== 4) {
                //Not ready
                return;
            }

            if (json_req.status == 200) {
                try {
                    response = JSON.parse(json_req.responseText);
                } catch (e) {
                    console.error("Failed to verify form. Invalid JSON response from server");
                    self.endValidation();
                    return;
                }
                response = self.filterJSONResponse(response);
                var num_elems = self.form.elements.length;

                for (var x = 0; x < num_elems; x++) {
                    var form_elem = self.form.elements[x];
                    var elem_name = form_elem.name.replace(/\[\]$/, "");

                    var multi_elem = false;
                    if (elem_name !== form_elem.name) {
                        multi_elem = true;
                    }
                    var model_property = self.elem_property_map[elem_name] || null;
                    if (!model_property) {
                        continue;
                    }
                    var property_array = model_property.split(".");

                    var property_error_msg = null;
                    if (multi_elem === false) {
                        property_error_msg = response[model_property] || null;
                        if (property_error_msg) { 
                            self.setErrorOnFormElem(form_elem, property_error_msg);
                        }
                    } else if (multi_elem === true) {
                        var obj = response[property_array[0]] || null;
                        if (obj) {
                            var id = (self.multi_elems[form_elem.name]).indexOf(form_elem); 
                            var sub_obj = obj[id] || null;
                            for (var j = 1; j < property_array.length && sub_obj; j++) {
                                var prop_name = property_array[j];
                                sub_obj = sub_obj[prop_name] || null;
                            }
                            if (sub_obj) {
                                property_error_msg = sub_obj;
                                self.setErrorOnFormElem(form_elem, property_error_msg);
                            }
                        }
                    }
                }

            } else if (json_req.readyState === 4) {
                console.error("Server failed to respond succsefully");
            }

            self.endValidation();
        };
    },

    validateJSON: function(model_json_doc) {
        if (!this.model_name) {
            var self = this;
            //We cannot resubmit form if it is being submitted
            //This ensures the form is completed submission callback
            //before firing it again
            setTimeout(function () {
                self.endValidation();
            }, 1);

            return;
        }

        var json_req = new XMLHttpRequest();
        json_req.onreadystatechange = this.handleValidationResults(json_req);
        json_req.open("POST", "/apiv2/ModelPropertyValidator/" + this.model_name, true);
        json_req.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        json_req.send(JSON.stringify(model_json_doc));
    },

    addValidationCheck: function (callback) {
        this.extra_validations.push(callback);
    },

    runExtraValidationChecks: function (form_elem, model_property) {
        var ret = false;
        for (var x = 0; x < this.extra_validations.length; x++) {
            var cb = this.extra_validations[x];
            ret = cb.call(this, form_elem, model_property);
            if (ret === true) {
                return true;
            }
        }

        return ret;
    },

    addJSONFilter: function(callback) {
        this.json_filters.push(callback);
    },

    addJSONResponseFilter: function(callback) {
        this.json_response_filters.push(callback);
    },

    filterJSONModel: function() {
        for (var x = 0; x < this.json_filters.length; x++) {
            var cb = this.json_filters[x];
            this.model_json = cb.call(this, this.model_json) || this.model_json;
        }
    },

    filterJSONResponse: function(response) {
        for (var x = 0; x < this.json_response_filters.length; x++) {
            var cb = this.json_response_filters[x];
            response = cb.call(this, response) || response;
        }

        return response;
    },

    setModelJsonProperty: function (property_array, multi_elem, form_elem, value) {
        var value_obj = value;
        var model_property_name = property_array[0];

        if (property_array.length > 1) {
            if (multi_elem) {
                var id = (this.multi_elems[form_elem.name]).indexOf(form_elem); 
                if (id === -1) {
                    return;
                }
                value_obj = this.model_json[model_property_name][id];
                if (!value_obj) {
                    value_obj = {};
                    this.model_json[model_property_name].push(value_obj);
                }
            } else {
                value_obj = {};
            }

            var sub_obj = value_obj;
            for (var x = 1; x < property_array.length; x++) {
                var prop_name = property_array[x];
                if ((x+1) < property_array.length) {
                    sub_obj = sub_obj[prop_name] = {};
                } else {
                    sub_obj = sub_obj[prop_name] = value;
                }
            }
        }

        if (!multi_elem) {
            this.model_json[model_property_name] = value_obj;
        }
    },

    addFormSubmitCallback: function (cb) {
        this.form.addEventListener("submit", cb);
    },

    isMultiElement: function (name) {
        var elem_name = name.replace(/\[\]$/, "");

        var is_multi_elem = false;
        if (elem_name !== name) {
            is_multi_elem = true;
        }

        return is_multi_elem;
    },

    multiElemHasValidation: function (form_elem) {
        if (this.multi_elems[form_elem.name].indexOf(form_elem) === -1) {
            return false;
        } else {
            return true;
        }
    },

    isValid: function() {
        return (this.submitted === true && this.has_error === false);
    },

    validate: function() {
        var num_elems = this.form.elements.length;
        
        if (this.submitted === true && !this.has_error) {
            return true;
        }
        this.focused = false;
        this.submitted = true;

        this.clearErrors();

        this.model_json = {};
        this.multi_elems = [];

        for (var x = 0; x < num_elems; x++) {
            var ret = false;
            var form_elem = this.form.elements[x];

            var elem_name = form_elem.name.replace(/\[\]$/, "");
            var multi_elem = this.isMultiElement(form_elem.name);

            var model_property = this.elem_property_map[elem_name] || null;
            if (model_property === null) {
                continue;
            }

            var property_array = model_property.split(".");

            if (multi_elem) {
                if (!Array.isArray(this.multi_elems[form_elem.name])) {
                    var elements_array = Array.prototype.slice.call(document.getElementsByName(form_elem.name));
                    this.multi_elems[form_elem.name] = elements_array;
                    if (this.ignore_last_multi_elem) {
                        this.multi_elems[form_elem.name].pop();
                    }
                }
                if (!this.multiElemHasValidation(form_elem)) {
                    continue;
                }
                

                if (!Array.isArray(this.model_json[property_array[0]])) {
                    this.model_json[property_array[0]] = [];
                }
            }

            ret = this.runExtraValidationChecks(form_elem, model_property);
            if (ret === true) {
                continue;
            }

            switch (form_elem.type) {
                case "checkbox":
                    this.setModelJsonProperty(property_array, multi_elem, form_elem, form_elem.checked);
                    break;
                case "radio":
                    if (form_elem.checked) {
                        this.setModelJsonProperty(property_array, multi_elem, form_elem, form_elem.value);
                    }
                    break;
                case "number":
                    var int_val = null;
                    if (form_elem.value !== "") {
                        int_val = parseInt(form_elem.value);
                    }
                    this.setModelJsonProperty(property_array, multi_elem, form_elem, int_val);
                    break;
                case "date":
                case "time":
                case "tel":
                case "email":
                case "password":
                case "text":
                case "url":
                case "textarea":
                case "hidden":
                    this.setModelJsonProperty(property_array, multi_elem, form_elem, form_elem.value);
                    break;
                case "select-one":
                    var select_option = form_elem.options[form_elem.selectedIndex] || null;
                    var val = (select_option) ? select_option.value : "";
                    this.setModelJsonProperty(property_array, multi_elem, form_elem, val);
                    break;
                default:
                    console.error("Unsupported form element type: " + form_elem.type);
                    break;
            }
        }

        this.filterJSONModel();
        this.validateJSON(this.model_json);

        this.submit_clicked = false;

        return false;
    },

    ignoreLastMultiElem: function (ignore) {
        this.ignore_last_multi_elem = ignore;
    }
};

